import { render, staticRenderFns } from "./Contacte.vue?vue&type=template&id=1e5b6be5&scoped=true&"
import script from "./Contacte.vue?vue&type=script&lang=js&"
export * from "./Contacte.vue?vue&type=script&lang=js&"
import style0 from "./Contacte.vue?vue&type=style&index=0&id=1e5b6be5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e5b6be5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VRadio,VRadioGroup,VTextField,VTextarea})
