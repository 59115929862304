<template>
    <div class="statique">
        <div class="entete">
            <div class="fil-ariane">
                <router-link to="/">Accueil</router-link>
                <span> > </span>
                <router-link to="/nous-contacter">Nous contacter</router-link>
            </div>
            <h2 class="titre">Nous contacter</h2>
        </div>
        <div class="carte-arrondie formulaire">
            <div class="contenu">
                <div class="coordonnees" v-if="!isLogged">
                    <v-text-field
                        label="Société"
                        v-model="formulaire.societe.valeur"
                    />
                    <v-text-field
                        label="Nom et prénom *"
                        v-model="formulaire.nom_prenom.valeur"
                    />
                    <v-text-field
                        label="Adresse Email *"
                        v-model="formulaire.mail.valeur"
                    />
                    <v-text-field
                        label="Téléphone *"
                        v-model="formulaire.tel.valeur"
                    />
                    <v-text-field
                        label="Adresse *"
                        v-model="formulaire.adresse.valeur"
                    />
                    <div class="cp-ville">
                        <v-text-field
                            label="Code postal *"
                            v-model="formulaire.cp.valeur"
                            class="cp"
                        ></v-text-field>
                        <v-text-field
                            label="Ville *"
                            v-model="formulaire.ville.valeur"
                        />
                    </div>
                </div>
                <div v-else class="info-importante">
                    <p>
                        Important: ce formulaire est exclusivement dédié aux demandes commerciales. Aucune instruction concernant la production ou l'expédition des commandes ne pourra être prise en compte.
                    </p>
                </div>
                <v-radio-group
                    label="Nature de votre demande *"
                    v-model="formulaire.nature.valeur"
                    v-on:change="focusMessage"
                >
                    <v-radio v-for="(motif, index) in motifs" :key="index"
                             :label="motif"
                             :value="motif"
                    ></v-radio>
                </v-radio-group>
                <v-textarea
                    label="Message *"
                    :rows="isLogged ? 15 : 5"
                    v-model="formulaire.message.valeur"
                    outlined
                    :disabled="!formulaire.nature.valeur"
                    ref="message"
                >

                </v-textarea>
                <btn-theme @click="envoyer" :disabled="!formulaireValide">Envoyer le message</btn-theme>
                <br><br><p style="color:red; font-size: 18px;text-align:left;margin-bottom: 0;">Nous prendrons contact avec vous rapidement</p>
                <p style="color:red; font-size: 12px;text-align:left;margin-bottom: 0;">* Champs obligatoires</p>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import BtnTheme from "../../components/theme/BtnTheme";
import {mapGetters} from "vuex";

export default {
    name: 'Formulaire',
    components: {
        BtnTheme,
    },
    data () {
        return {
            urlEnvoi:    process.env.VUE_APP_API_URL + '/boutique/message',
            formulaire: {
                societe: {
                    libelle: 'Société',
                    valeur: '',
                },
                nom_prenom: {
                    libelle: 'Nom et prénom',
                    valeur: '',
                },
                mail: {
                    libelle: 'Mail',
                    valeur: '',
                },
                tel: {
                    libelle: 'Téléphone',
                    valeur: '',
                },
                adresse: {
                    libelle: 'Adresse',
                    valeur: '',
                },
                cp: {
                    libelle: 'Code postal',
                    valeur: '',
                },
                ville: {
                    libelle: 'Ville',
                    valeur: '',
                },
                nature: {
                    libelle: 'Nature de la demande',
                    valeur: '',
                },
                message: {
                    libelle: 'Message',
                    valeur: '',
                },
            },
            motifs: [
                "Demande d'informations",
                "Demande de devis"
            ],
        }
    },
    computed: {
        ...mapGetters([
            'isLogged',
            'portailId',
            'user',
        ]),
        expediteurIdentifie() {
            return this.formulaire.mail.valeur !== ''       //que l'on soit ou non authentifier, ces champs sont requis
                && this.formulaire.nom_prenom.valeur !== '';
        },
        natureSelectionnee() {
            return this.formulaire.nature.valeur.length > 0
        },
        messageSaisi() {
            return this.formulaire.message.valeur.length > 0
        },
        formulaireValide() {
            return (this.isLogged || (              //si on est pas authentifié, les champs doivent être remplis
                    this.formulaire.societe.valeur !== ''
                    && this.formulaire.tel.valeur !== ''
                    && this.formulaire.adresse.valeur !== ''
                    && this.formulaire.cp.valeur !== ''
                    && this.formulaire.ville.valeur !== ''
                ))
                && this.expediteurIdentifie
                && this.natureSelectionnee
                && this.messageSaisi
        }
    },
    methods: {
        envoyer() {
            let datas = [];
            for(let champ in this.formulaire) {
                if(!Object.hasOwnProperty.call(this.formulaire, champ)) continue;
                datas.push(this.formulaire[champ])
            }
            axios.post(this.urlEnvoi, {
                donnees: datas,
                portail_id: this.portailId
            }).then(() => {
                this.$store.commit('addSnackMessage', {msg: 'Votre message a été envoyé avec succès ! \n' +
                        'Notre conseiller prendra contact avec vous sous 48 heures.', color:'success', duration:10e3});
                this.$router.push('/');
            })
        },
        initChamps() {
            let donneesUtilisateur = this.user;
            this.formulaire.nom_prenom.valeur = donneesUtilisateur.name;
            this.formulaire.mail.valeur = donneesUtilisateur.email;

        },
        focusMessage() {
            this.$refs.message.focus();
        }
    },
    mounted () {
        if (this.isLogged) this.initChamps();
    }
}
</script>
<style scoped lang="scss">
.formulaire {
    max-width: 440px;
    min-width:440px;
    .info-importante {
        text-align: left;
        font-size: 0.8em;
        font-weight: 500;
    }
    .contenu {
        padding: 20px;
        margin:auto;
        & .coordonnees {
            max-width: 800px;
            .cp-ville {
                .cp {
                    max-width: 30%;
                }
                display: flex;
                gap: 5px;
            }
        }
        & .titre {
            text-align:center;
            font-weight: 100;
            font-size: 2em;
            color: #023e7c;
        }
        & .texte {
            & .sous-titre {
                text-transform: none;
                font-size: 16px;
            }
            padding: 30px;
        }
    }
}

</style>
